.header__logo {
  width: 190.45px;
  height: 31px;
  background-image: url(../../../images/logo.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 34px;
  margin-bottom: 34px;
  margin-left: 4px;
  margin-right: 197.55px;
}

@media all and (min-width: 320px) and (max-width:1105px) {
  .header__logo-link {
    width: 100%;
  }
  .header__logo {
    margin: 0;
    width: 130px;
    height: 21.16px;
    margin-top: 18px;
    margin-bottom: 19.84px;
    margin-right: 40%;
  }
}

.header__nav {
  display: flex;
  align-items: center;
  margin-right: 73px;
}

@media all and (min-width:1106px) {
  .header__nav {
    margin-right: 6.52921699%;
  }
}

@media all and (max-width:1105px) {
  .header__nav {
    display: none;
  }
}
.location {
  margin-top: 140px;
  width: 76.736%;
  color: #080E2C;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
}
.location__title {
  margin: 0;
  font-size: 50px;
  line-height: 120%;
  width: 66%;
  margin-bottom: 30px;
  font-weight: 400;
}
.location__subtitle {
  margin: 0;
  font-size: 28px;
  line-height: 130%;
  font-weight: 400;
  margin-bottom: 31px;
}
.location__image {
  width: 100%;
}

@media all and (min-width: 501px) and (max-width: 1439px) {
  .location {
    margin-top: 110px;
    width: 88.28125%;;
  }
  .location__title {
    font-size: 38px;
    line-height: 120%;
    width: 75.6%;
    margin-bottom: 30px;
  }
  .location__subtitle {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 40px;
  }
  .location__image {
    width: 100%;
  }
}

@media all and (min-width: 320px) and (max-width: 500px) {
  .location {
    margin-top: 120px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .location__title {
    font-size: 28px;
    line-height: 130%;
    width: 90.625%;
    margin-bottom: 24px;
  }
  .location__subtitle {
    font-size: 18px;
    line-height: 130%;
    margin-bottom: 40px;
    width: 85.625%; 
  }
  .location__image {
    width: 100%;
  }
}

@media (width: 768px) {
  .location__title {
    margin-bottom: 33px;
  }
  .location__subtitle {
    margin-left: 5px;
  }
}
@media (width: 320px) {
  .location__subtitle {
    width: 88%;
    margin-left: -9px;
  }
}


@import url('./__logo/header__logo.css');
@import url('./__nav/header__nav.css');
@import url('./__nav-list/header__nav-list.css');
@import url('./__link/header__link.css');
@import url('./__phone/header__phone.css');
@import url('./__phone-logo/header__phone-logo.css');
@import url('./__phone-number/header__phone-number.css');
@import url('./__nav-element/header__nav-element.css');
@import url('./__link-arrow/header__link-arrow.css');
@import url('./__burger/header__burger.css');

.header {
  position: fixed;
  color: #080E2C;
  background-color: #FFFFFF;
  font-weight: 400;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  z-index: 1000;
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 11px rgba(8, 14, 44, 0.1);
}

.header__container {
  display: flex;
  margin: 0 auto;
}

@media all and (min-width: 320px) and (max-width: 500px) {
  .header__container {
    width: 90.625%;
  }
}
@media all and (min-width: 500px) and (max-width: 1105px) {
  .header__container {
    width: 88.28125%;
  }
}
@media all and (min-width: 1106px) {
  .header__container {
    width: 1118px;
  }
}
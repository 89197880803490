.burger {
  position: absolute;
  top: 59px;
  right: 0;
  display: none;
  width:260px;
  background-color:#FFFFFF;
}

.burger_active {
  display: block;
  box-shadow: inset 0px 4px 11px rgba(8, 14, 44, 0.1);
}

.burger__nav {
  display: flex;
  flex-direction: column;
  width: 197px;
  margin: 32px auto 614px;
}

.burger__nav-list {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.burger__nav-element {
  margin-bottom: 16px;
}
.drop-down-menu__nav-element:last-child {
  margin-right: 0;
}

.burger__link {
  text-decoration: none;
  color: #080E2C;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.burger__phone {
  display: flex;
  align-items: center;
  margin-top: 264px;
}

.burger__phone-logo {
  width: 17px;
  height: 17px;
  margin-right: 15px;
}

.burger__phone-number {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #080E2C;
}
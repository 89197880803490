.footer {
  display: flex;
  flex-wrap: wrap;
  color: #080E2C;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
}
.footer__phone {
  display: flex;
  align-items: center;
}
.footer__phone-number {
  margin: 0;
  color: #080E2C;
}
.footer__email {
  display: flex;
  align-items: center;
}
.footer__email-text {
  margin: 0;
  color: #080E2C;
}
.footer__politics {
  color: #080E2C;
  opacity: 0.5;
  text-decoration: underline;
}

@media (min-width: 320px) and (max-width: 767px) {
  .footer {
    width: 90.625%;
    flex-direction: column;
  }
  .footer__logo {
    width: 159px;
    height: 42px;
    margin-bottom: 40px;
  }
  .footer__phone {
    margin-bottom: 15px;
  }
  .footer__phone-logo {
    width: 18px;
    height: 18px;
    margin-right: 12px;
  }
  .footer__phone-number {
    font-size: 18px;
    line-height: 130%;
  }
  .footer__email {
    margin-bottom: 35px;
  }
  .footer__email-logo {
    width: 20px;
    height: 16px;
    margin-right: 12px;
  }
  .footer__email-text {
    font-size: 18px;
    line-height: 130%;
  }
  .footer__politics {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 44px;
  }
}

@media (min-width: 768px) and (max-width: 1201px) {
  .footer {
    width: 88.28125%;
    margin-bottom: 49px;
    justify-content: space-between;
  }
  .footer__logo {
    width: 207px;
    height: 52px;
    margin-bottom: 20px;
    margin-right: 282px;
    margin-right: 41.59292%;
  }
  .footer__phone {
    height: 22px;
    margin-top: 25px;
  }
  .footer__phone-logo {
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }
  .footer__phone-number {
    font-size: 18px;
    line-height: 22px;
  }
  .footer__email {
    width: 187px;
    order: 4;
    height: 22px;
  }
  .footer__email-logo {
    width: 15px;
    height: 12px;
    margin-right: 10px;
  }
  .footer__email-text {
    font-size: 18px;
    line-height: 22px;
  }
  .footer__politics {
    order: 3;
    margin: 0;
    margin-top: 3px;
    font-size: 14px;
    line-height: 17px;
  }
  .footer__link {
    margin-right: 37.61%;
  }
}

@media (min-width: 1202px) {
  .footer {
    width: 78.194444%;
    margin-bottom: 45px;
    justify-content: space-between;
  }
  .footer__logo {
    width: 255px;
    height: 64px;
    margin: 0;
    margin-left: 8px;
    margin-right: 450px;
    margin-bottom: 20px;
  }
  .footer__phone {
    width: 226px;
    height: 27px;
    margin-top: 18px;
  }
  .footer__phone-logo {
    width: 18px;
    height: 18px;
    margin-right: 22px;
  }
  .footer__phone-number {
    font-size: 18px;
    line-height: 22px;
  }
  .footer__email {
    width: 226px;
    order: 4;
    height: 22px;
    margin-top: -22px;
  }
  .footer__email-logo {
    width: 20px;
    height: 16px;
    margin-right: 12px;
  }
  .footer__email-text {
    font-size: 18px;
    line-height: 22px;
  }
  .footer__politics {
    order: 3;
    margin: 0;
    font-size: 14px;
    line-height: 17px;
  }
  .footer__link {
    margin-right: 37.61%;
  }
}
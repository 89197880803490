@media (max-width: 1440px) {
  .lines-main {
    display: none;
  }
}
@media (min-width: 1441px) and (max-width: 1650px) {
  .lines-main {
    position: absolute;
    overflow: hidden;
    left: calc((100vw - 1110px)/2 + 640px);
    --left: calc((100vw - 1110px)/2 + 640px);
    width: calc(100vw - (var(--left)));
    top: 1581px;
    height: 1450px;
    z-index: 1;
  }
  .line-main_first {
    position: absolute;
    width: 941px;
    bottom: 0;
    left: 0;
  }
  .line-main_second {
    position: absolute;
    width: 665px;
    bottom: 0;
    left: 308px;
  }
}
@media (min-width: 1651px) {
  .lines-main {
    position: absolute;
    overflow: hidden;
    right: 0;
    width: 742px;
    top: 1581px;
    height: 1450px;
    z-index: 1;
  }
  .line-main_first {
    position: absolute;
    width: 941px;
    bottom: 0;
    left: 0;
  }
  .line-main_second {
    position: absolute;
    width: 665px;
    bottom: 0;
    left: 308px;
  }
}
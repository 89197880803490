.intro__container-image {
  position: relative;
  height: 595px;
  overflow: hidden;
  width: 51.041666%;
}

.intro__vector_first {
  position: absolute;
  bottom: 0;
  left: 200px;
  z-index: 1;
  width: 535px;
  height: 843px;
}
.intro__vector_second {
  position: absolute;
  bottom: 0;
  left: -185px;
  z-index: 1;
  width: 535px;
  height: 843px;
}

.intro__pay {
  position: absolute;
  bottom: 0;
  left: 25px;
  height: 591px;
  width: 591px;
  z-index: 2;
}

.intro__circle {
  position: absolute;
  top: 276px;
  left: 388px;
  z-index: 4;
  width: 192px;
  height: 192px;
  background-color: #FFFFFF;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* backdrop-filter: blur(20px); */
}

.intro__circle-text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
}

.intro__circle-shadow {
  position: absolute;
  z-index: -1;
  width: 40%;
  height: 40%;
  top: 40%;
  left: 30%;
  border-radius: 50%;
  background-color: rgba(8, 14, 44, 0.4);
  filter: blur(100px);
}

@media all and (min-width: 1100px) and (max-width: 1439px) {
  .intro__container-image {
    height: 530px;
  }
  .intro__circle {
    width: 192px;
    height: 192px;
  }
  .intro__circle-text {
    font-size: 24px;
  }
  .intro__circle-shadow {
    width: 16%;
    height: 23%;
    top: 62%;
    left: 60%;
  }
}
@media all and (min-width: 768px) and (max-width: 1099px) {
  .intro__container-image {
    height: 375px;
  }
  .intro__circle {
    width: 135px;
    height: 135px;
  }
  .intro__circle-text {
    font-size: 18px;
  }
  .intro__circle-shadow {
    width: 16%;
    height: 16%;
    top: 62%;
    left: 50%;
  }
}

@media all and (min-width: 768px) and (max-width: 1439px) {
  .intro {
    margin-top:59px;
  }
  .intro__container-image {
    width: 51.4323%;
  }
  .intro__vector_first {
    position: absolute;
    width: 83.7974%;
    height: 134.625%;
    bottom: 0;
    left: 16.2025%;
  }
  .intro__vector_second {
    bottom: 0;
    left: -35%;
    width: 83.7974%;
    height: 134.625%;
  }
  .intro__pay {
    bottom: -11.23%;
    left: -9.62%;
    height: 106.32911%;
    width: 106.32911%;
    z-index: 2;
  }
  .intro__circle {
    top: 53.67%;
    left: 44.81%;
  }
}

@media all and (min-width: 501px) and (max-width: 767px) {
  .intro {
    margin-top:59px;
  }
  .intro__container-image {
    width: 50%;
    height: 280px;
  }
  .intro__vector_first {
    position: absolute;
    width: 83.7974%;
    height: 134.625%;
    bottom: 0;
    left: 16.2025%;
  }
  .intro__vector_second {
    bottom: 0;
    left: -33.924%;
    width: 83.7974%;
    height: 134.625%;
  }
  .intro__pay {
    bottom: -11.23%;
    left: -9.62%;
    height: 106.32911%;
    width: 106.32911%;
    z-index: 2;
  }
  .intro__circle {
    top: 50.67%;
    left: 44.81%;
    width: 135px;
    height: 135px;
  }
  .intro__circle-text {
    font-size: 18px;
  }
}

@media all and (min-width: 320px) and (max-width: 500px) {
  .intro__container-image {
    height: 404px;
  }
  .intro__circle {
    width: 135px;
    height: 135px;
  }
  .intro__circle-text {
    font-size: 18px;
  }
}

@media all and (min-width: 320px) and (max-width: 500px) {
  .intro__container-image {
    margin-top: -76px;
    order: 2;
    width: 100%;
    z-index: 11;
  }
  .intro__vector_first {
    position: absolute;
    width: 284px;
    height: 404px;
    bottom: 0;
    left: 80.625%;
  }
  .intro__vector_second {
    bottom: 0;
    left: 33.1875%;
    width: 88.75%;
    height: 100%;
  }
  .intro__pay {
    bottom: 2.6875%;
    left: -13.125%;
    height: 80.94%;
    width: 102.1875%;
    z-index: 2;
  }
  .intro__circle {
    top: 51.48%;
    left: 50%;
  }
  .intro__circle-shadow {
    width: 16%;
    height: 23%;
    top: 62%;
    left: 70%;
  }
}

@media all and (min-width: 321px) and (max-width: 434px) {
  .intro__vector_second {
    left: 39.1875%;
  }
}
@media all and (min-width: 435px) and (max-width: 500px) {
  .intro__vector_second {
    left: 41.1875%;
  }
}

@media (min-width: 1106px) {
  .intro__circle-text {
    width: 70.83333%;
  }
}

@media (width: 768px) {
  .intro__container-image {
    position: relative;
    overflow: hidden;
    width: 52.4739583333%;
    height: 384px;
  }
  
  .intro__vector_first {
    position: absolute;
    bottom: 0;
    left: 68px;
    z-index: 1;
    width: 331px;
    height: 521px;
  }
  .intro__vector_second {
    position: absolute;
    bottom: 0;
    left: -134px;
    z-index: 1;
    width: 331px;
    height: 521px;
  }
  
  .intro__pay {
    position: absolute;
    bottom: -50px;
    left: -38px;
    height: 420.51px;
    width: 420.51px;
    z-index: 2;
  }
  
  .intro__circle {
    position: absolute;
    top: 212px;
    left: 177px;
    z-index: 5;
    width: 135px;
    height: 135px;
    background-color: #FFFFFF;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .intro__circle-text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    width: 75%;
  }
}

@media (width: 320px) {
  .intro__container-image {
    margin-top: -63px;
    order: 2;
    width: 100%;
    z-index: 11;
  }
  
  .intro__vector_first {
    position: absolute;
    bottom: 0;
    left: 258px;
    z-index: 1;
    width: 197px;
    height: 276px;
  }
  .intro__vector_second {
    position: absolute;
    bottom: 0;
    left: 106px;
    z-index: 1;
    width: 284px;
    height: 404px;
  }
  
  .intro__pay {
    position: absolute;
    bottom: 8px;
    left: -42px;
    height: 327.55px;
    width: 327.55px;
    z-index: 2;
  }
  
  .intro__circle {
    position: absolute;
    bottom: 61px;
    left: 154px;
    z-index: 5;
    width: 135px;
    height: 135px;
    background-color: #FFFFFF;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* filter: blur(20px); */
  }
  
  .intro__circle-text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    width: 75%;
  }
}

@media (min-width: 1441px) {
  .intro__container-image {
    overflow: visible;
  }
}
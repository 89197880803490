.intro__container-text {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 10;
  /* width: 37.5%; */
  width: 35%;
  background-color: #ffffff;
}
.intro__title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 90px;
  line-height: 110%;
  margin-top: 89px;
  margin-bottom: 30px;
}

.intro__text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 130%;
  margin-top: 0;
  margin-bottom: 25px;
  width: 445px;
}
.intro__text:last-child {
  margin-bottom: 0;
}

@media (width: 1440px) {
  .intro__text {
    width: 410px;
  }
}

@media all and (min-width: 768px) and (max-width: 1100px) {
  .intro__container-text {
    align-items: flex-start;
    width: 44%;
  }
  .intro__title {
    font-size: 60px;
    line-height: 110%;
    margin-top: 60px;
    margin-bottom: 30px;
  }
  .intro__text {
    font-size: 22px;
    line-height: 150%;
    margin-top: 0;
    margin-bottom: 10px;
    width: 100%;
  }
}

@media all and (min-width: 501px) and (max-width: 767px) {
  .intro__container-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    width: 44%;
    background-color: #ffffff;
  }
  .intro__title {
    font-size: 50px;
    line-height: 110%;
    margin-top: 30px;
    margin-bottom: 16px;
  }
  .intro__text {
    font-size: 18px;
    line-height: 130%;
    margin-top: 0;
    margin-bottom: 12px;
    width: 100%;
  }
}

@media all and (min-width: 320px) and (max-width: 500px) {
  .intro__container-text {
    order: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    width: 90.625%;
    background-color: #ffffff;
  }
  .intro__title {
    font-size: 50px;
    line-height: 110%;
    margin-top: 30px;
    margin-bottom: 16px;
  }
  .intro__text {
    font-size: 18px;
    line-height: 130%;
    margin-top: 0;
    margin-bottom: 12px;
    width: 100%;
  }
}

@media (width: 768px) {
  .intro__container-text {
    margin-left: -5px;
  }
  .intro__text:last-child {
    margin-bottom: 43px;
  }
}
.services {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 120px;
}

.services__container {
  width: 77%;
  margin-top: 140px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  color: #080E2C;
  margin-bottom: 120px;
  z-index: 2;
}

.services__title {
  margin:0;
  margin-bottom: 72px;
  font-weight: 400;
  font-size: 50px;
  line-height: 120%;
  width: 65%;
}

.services__card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.services__card {
  width: 48.65%;
  background-color: #FFFFFF;
  margin-bottom: 30px;
  position: relative;
  text-decoration: none;
  color: #080E2C;
  box-shadow: 0px 16px 72px -23px rgba(8, 14, 44, 0.15);
}

.services__image {
  width: 100%
}
.services__text {
  font-weight: 500;
  font-size: 28px;
  line-height: 130%;
  width: 74%;
  margin:40px;
  margin-top: 35px;
}
.services__arrow {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 40px;
  bottom: 40px;
}

.services__line_first {
  position: absolute;
  bottom: 0;
  left: 805px;
  z-index: 1;
  width: 941px;
  height: 1450px;
}
.services__line_second {
  position: absolute;
  bottom: 0;
  left: 1113px;
  z-index: 1;
  width: 665px;
  height: 891px;
}

@media all and (min-width: 501px) {
  .services__card:nth-last-child(-n+2){
    margin-bottom: 0;
  }
}

@media all and (min-width: 501px) and (max-width:1439px) {
  .services {
    margin-bottom: 120px;
  }
  .services__container {
    width: 88.28125%;
    margin-top: 120px;
    margin-bottom: 80px;
  }
  .services__title {
    margin-bottom: 40px;
    font-size: 38px;
    line-height: 120%;
    width: 75.74%;
  }
  .services__card {
    width: 47.7876%;
  }
  .services__text {
    font-size: 20px;
    line-height: 130%;
    width: 77.77%;
    margin:20px;
    margin-bottom: 26px;
  }
  .services__arrow {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 6.1728%;
    bottom: 6.1728%;
  }
  .services__line_first {
    left: 56.51%;
    bottom: 0;
    z-index: 1;
    width: 83.9843%;
    height: 996px;
  }
  .services__line_second {
    left: 84.1145%;
    bottom: 0;
    z-index: 1;
    width: 59.375%;
    height: 612px;
  }
}

@media all and (min-width: 320px) and (max-width:500px) {
  .services {
    margin-bottom: 0;
  }
  .services__container {
    width: 90.625%;
    margin-top: 120px;
    margin-bottom: 0;
  }
  .services__title {
    margin-bottom: 46px;
    font-size: 28px;
    line-height: 130%;
    width: 100%;
  }
  .services__card-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .services__card {
    width: 100%;
  }
  .services__card:last-child {
    margin-bottom: 113px;
  }
  .services__text {
    font-size: 18px;
    line-height: 130%;
    width: 73.2069%;
    margin:20px;
  }
  .services__arrow {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 6.8965%;
    bottom: 6.8965%;
  }
  .services__line_first {
    display: none;
  }
  .services__line_second {
    display: none;
  }
}


@media (width: 768px) {
  .services__container {
    margin-bottom: 80px;
  }
  .services__image {
    width: 100%;
    height: 170px;
  }
  .services__text {
    font-size: 20px;
    line-height: 130%;
    width: 90%;
    margin:20px;
    margin-top: 15px;
    margin-bottom: 26px;
  }
}
@media (width: 320px) {
  .services__image {
    width: 100%;
    height: 160px;
  }
  .services__text {
    font-size: 18px;
    line-height: 130%;
    width: 90%;
    margin:20px;
    margin-top: 16px;
    margin-bottom: 20px;
  }
  .services__text_service {
    width: 54%;
  }
}

@media (min-width: 1441px) {
  .services__line_first {
    display: none;
  }
  .services__line_second {
    display: none;
  }
}
@import url('../vendor/normalize.css');
@import url('../blocks/header/header.css');
@import url('../blocks/drop-down-menu/drop-down-menu.css');
@import url('../blocks/burger/burger.css');
@import url('../blocks/main/intro/intro.css');
@import url('../blocks/main/location/location.css');
@import url('../blocks/main/services/services.css');
@import url('../blocks/footer/footer.css');
@import url('../blocks/lines/lines-main.css');

@import url('../blocks/repetitive-blocks/studio/studio.css');

.body {
  background-color: #ffffff;
  min-width: 320px;
}

.page {
  margin: 0 auto;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: #ffffff;
}

a {
  text-decoration: none;
}
.header__burger {
  display: none;
}

@media all and (max-width: 1105px) {
  .header__burger {
    display: block;
    cursor: pointer;
  }
}

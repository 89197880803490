@import url('./__container-image/intro__container-image.css');
@import url('./__container-text/intro__container-text.css');

.intro {
  color: #080E2C;
  background-color: #ffffff;
  display: flex;
  width: 100%;
  margin-top:100px;
  z-index: 1;
  position: relative;
}

@media all and (min-width: 320px) and (max-width: 1439px) {
  .intro {
    margin-top:59px;
  }
}

@media all and (min-width: 320px) and (max-width: 500px) {
  .intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 1;
    position: relative;
  }
}
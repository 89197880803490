.studio {
  margin-bottom: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.studio__link {
  text-align: none;
}

@media (min-width: 1106px) {
  .studio {
    width: 77.08333333%;
  }
  .studio__logo {
    width: 70px;
  }
}
@media (min-width: 768px) and (max-width: 1105px) {
  .studio {
    width: 88.28125%;
  }
  .studio__logo {
    width: 70px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .studio {
    width: 90.625%;
  }
  .studio__logo {
    width: 70px;
  }
}
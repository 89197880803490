@import url('./__nav/drop-down-menu__nav.css');
@import url('./__nav-list/drop-down-menu__nav-list.css');
@import url('./__nav-element/drop-down-menu__nav-element.css');
@import url('./__link/drop-down-menu__link.css');

.drop-down-menu {
  position: absolute;
  top: 99px;
  /* display:flex; */
  flex-direction: column;
  align-items: center;
  display: none;

}

.drop-down-menu_active {
  display: flex;
  z-index:10;
}

.drop-down-menu__container {
  position: absolute;
  top: 0px;
  z-index: 10;
  width:100%;
  max-width: 1440px;
  background-color:#EDEFF4;
}

.drop-down-menu__shadow {
  background-color: black;
  width: 100vw;
  height: 100vh;
  opacity: 0.2;
}